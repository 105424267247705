import { Box, Button, Form, FormField, Text } from "grommet";
import React from "react";
import { opayRef } from "../services/helpers";
export default function RefForm({ reference, merchant, onSuccess }) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const pay = async (mobile) => {
    try {
      setLoading(true);
      const init = await opayRef(reference.uid, mobile);
      if (init) {
        // window.location = "/wallet-success/" + trans.uid;
        // setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          onSuccess();
        }, 1000);
        setTimeout(() => {
          setSuccess(false);
        }, 10000);
      }
    } catch (e) {
      window.location = "/error?e=" + e.message;
    }
  };
  return (
    <>
      <Form
        validate="change"
        onReset={(event) => console.log("")}
        onSubmit={({ value }) => pay(value.mobile)}
        onValidate={(validationResults) => {
          setValid(validationResults.valid);
        }}
      >
        <FormField
          label="Mobile Number"
          name="mobile"
          placeholder="Enter your mobile number"
          required
          validate={[
            {
              regexp: /^(\+\d{1,3}[- ]?)?\d{11}$/,
              message: "isn't a valid mobile number",
            },
            (mobile) => {
              if (mobile.length !== 11) return "isn't a valid mobile number";
              return undefined;
            },
          ]}
        />
        <Box direction="row" justify="between" margin={{ top: "medium" }}>
          <Button
            type="submit"
            label={<Text color="white">Pay Now</Text>}
            disabled={!valid || loading}
            primary
            size="large"
          />
        </Box>
      </Form>
    </>
  );
}
