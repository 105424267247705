import { Box, Image, Main, ResponsiveContext } from "grommet";
import React from "react";
import styled from "styled-components";
import LoadingView from "../components/loading";
import RefForm from "../components/reference-form";
import SiteLayout from "../layout";
import { enqiryRef } from "../services/helpers";
export default function Reference(props) {
  const size = React.useContext(ResponsiveContext);
  let refId = props.location.pathname.replace("/r/", "");
  refId = refId.replace("/", "");
  const [reference, setReference] = React.useState({});
  const [merchant, setMerchant] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await enqiryRef(refId);
        response.data.status === 200
          ? setReference(response.data.data.reference)
          : (window.location = "/error?e=" + response.data.error);
        response.data.status === 200
          ? setMerchant(response.data.data.merchant)
          : (window.location = "/error?e=" + response.data.error);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        window.location = "/error?e=" + e.message;
      }
    }

    fetchMyAPI();
  }, [refId]);
  return (
    <SiteLayout>
      {loading && <LoadingView />}
      {!loading && (
        <WrapConontroller>
          <Main direction="row">
            <Box
              width={size === "small" ? "100%" : "580px"}
              pad={{ left: "medium" }}
            >
              <Box justify="center" align="start" pad="small" height="80px">
                <Image src={require("../images/logo.svg")} />
              </Box>
              <RefForm reference={reference} merchant={merchant} />
            </Box>
            {size !== "small" && (
              <Box flex={{ grow: 2 }} className="controlDiv">
                <Image
                  src={require("../images/elipse-background.svg")}
                  className="controlElipse"
                />
                <Box
                  className="controlIcon"
                  fill
                  justify="center"
                  align="center"
                >
                  <Image src={require("../images/mobile-payment.svg")} />
                </Box>
              </Box>
            )}
          </Main>
        </WrapConontroller>
      )}
    </SiteLayout>
  );
}

const WrapConontroller = styled.div`
  min-height: 100vh;
  .controlDiv {
    position: relative;
    overflow: hidden;
    .controlElipse {
      position: absolute;
      width: calc(100vh + 1000px);
      height: calc(100vh + 1000px);
      bottom: -250px;
      left: 10px;
      max-width: initial;
      z-index: 100;
    }
    .controlIcon {
      position: absolute;
      z-index: 101;
      left: 150px;
      min-width: 300px;
    }
  }
`;
